// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";


@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

$primary-color:#5867dd;

@mixin box-shadow($opacity) {
	box-shadow: 0 1px 3px rgba(0,0,0,$opacity), 0 1px 2px rgba(0,0,0,$opacity * 2);
}

.radio--card-group{
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .radio--card__item{
    position: relative;
    width: 45%;
    min-height: 100px;
    margin: 10px;
    border-radius:5px;
    @include box-shadow(0.1);
    .radio--card__content{
      box-sizing: border-box;
      padding: 20px;
      .radio--card__img{
        max-width: 150px;
      }
    }
    input[type="radio"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      opacity: 0;
    }
    input[type="radio"] + label:before {
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      content:" ";
      border-radius: 5px;
    }
    input[type="radio"] + label:after {
      position: absolute;
      width: 10px;
      height: 20px;
      right: 20px;
      top: 15px;
      content:" ";
      vertical-align:top;
      transform: rotate(0deg);
      border-bottom: 4px solid $primary-color;
      border-right: 4px solid $primary-color;
      opacity: 0;
    }
    input[type="radio"]:focus + label:before {
      border: 1px solid $primary-color;
    }
    input[type="radio"]:checked + label:before {
      border: 2px solid $primary-color;
    }
    input[type="radio"]:checked:focus + label:before {
      box-shadow: 0 0 5px $primary-color;
    }
    input[type="radio"]:checked + label:after {
      transform: rotate(45deg);  
      opacity: 1;
      transition: 0.5s;
    }
  }
}

.radioButtonGroup{
  display: flex;
  justify-content:space-between;
}

// #invoiceAmountRadio, #deductFromRent{
//   padding:5px;
// }